<template>
  <section :class="`wins section ${getCurrentLangs.lang}`">
    <Lottie
      :options="defaultOptions"
      class="wins__media mob"
      id="wins-animation-mob"
      @animCreated="handleAnimationMob"
      @play="startPlay"
    />
    <div class="wins__top">
      <h2 class="wins__title section__title iosTitle type1">
        <span class="text-wrapper">
          <span class="letters">
            {{ `${$t('mainPage.wins.title[0]')}` }}
          </span>
        </span>
        <br>
        <span class="text-wrapper second-part">
          <span class="letters">
            {{ $t('mainPage.wins.title[1]') }}
          </span>
        </span>
      </h2>
    </div>
    <div class="wins__content">
      <div class="wins__left grow">
        <h4 class="section__subtitle wins__subtitle">
          {{ $t('mainPage.wins.subtitle') }}
        </h4>
        <p class="section__description wins__description">{{ $t('mainPage.wins.text') }}</p>
        <ul class="wins__list">
          <li class="wins__item">
            <img class="wins__item--img-grey" src="@/assets/img/main-page/ukrainian_design_grey.png" alt="">
            <img class="wins__item--img-colour" src="@/assets/img/main-page/ukrainian_design_black.png" alt="">
          </li>
          <li class="wins__item">
            <img class="wins__item--img-grey" src="@/assets/img/main-page/kyiv_grey.png" alt="">
            <img class="wins__item--img-colour" src="@/assets/img/main-page/kyiv_black.png" alt="">
          </li>
          <li class="wins__item">
            <img class="wins__item--img-grey" src="@/assets/img/main-page/rx_grey.svg" alt="">
            <img class="wins__item--img-colour" src="@/assets/img/main-page/rx_black.svg" alt="">
          </li>
          <li class="wins__item">
            <img class="wins__item--img-grey" src="@/assets/img/main-page/iqvia_grey.png" alt="">
            <img class="wins__item--img-colour" src="@/assets/img/main-page/iqvia_black.png" alt="">
          </li>
        </ul>
      </div>
      <Lottie
        :options="defaultOptions"
        class="wins__media desc"
        id="wins-animation"
        @animCreated="handleAnimation"
        @play="startPlay"
      />
    </div>
  </section>
</template>

<script>
import {mapGetters} from 'vuex';
import Lottie from 'vue-lottie';
import animationData from '@/assets/style/animation/win.json';
import getElementVisible from '@/helper';

export default {
  name: 'wins',
  computed: {
    ...mapGetters([
      'getVisibleStage',
      'getCurrentLangs'
    ]),
  },
  components: {
    Lottie,
  },
  data() {
    return {
      defaultOptions: {
        animationData,
        loop: false,
        autoplay: false,
      },
    };
  },
  methods: {
    handleAnimation(anim) {
      this.anim = anim;
    },
    handleAnimationMob(anim2) {
      this.animMob = anim2;
    },
    play() {
      this.anim.play();
    },
    playMob() {
      this.animMob.play();
    },
    startPlay() {
      if (getElementVisible('wins-animation')) {
        this.play();
      }
      if (getElementVisible('wins-animation-mob')) {
        this.playMob();
      }
    },
  },
  mounted() {
    document.addEventListener('scroll', this.startPlay);
  },
};
</script>

<style lang="scss" scoped>
.wins {
  display: flex;
  flex-direction: column;
  // padding: 0 390px;
  padding-bottom: 186px;
  position: relative;
  z-index: 2;

  &__title {
    text-align: center;
  }

  &__subtitle {
    margin-bottom: 28px;
    // max-width: 80%;
    padding-left: 405px;
  }

  &__media {
    position: relative;
    z-index: 2;

    &.mob {
      display: none;
    }
  }

  &__description {
    // max-width: 80%;
    padding-left: 405px;
  }

  &__content {
    display: flex;
    margin-top: 18px;
    position: relative;
    z-index: 3;
  }

  &__left {
    margin-right: 9%;
    margin-top: 95px;
  }

  &__media {
    align-self: flex-start;
    padding-right: 300px;

    &.desc {
      min-width: 440px;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(2, 285px);
    margin-top: 52px;
    padding-left: 390px;
  }

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #92A1AE;
    height: 180px;
    position: relative;
    transition: all .3s ease;

    img {
      max-width: 53%;
      object-fit: contain;
      transition: opacity .3s ease;

    }

    &:nth-child(1) {
      img {
        &:first-child {
          // max-width: 95.3% !important;

          @media screen and (max-width: 705px) {
            // max-width: 80% !important;
          }
        }
      }
    }

    .wins__item--img-colour {
      opacity: 0;
      position: absolute;
    }

    &:first-of-type {
      img {
        max-width: 78%;
      }
    }

    &:nth-of-type(2) {
      border-left: none;

      img {
        &:first-child {
          // max-width: 98% !important;

          @media screen and (max-width: 705px) {
            // max-width: 80% !important;
          }
        }
      }

      img {
        max-width: 80%;
      }
    }

    &:nth-of-type(4) {
      border-left: none;

      img {
        &:first-child {
          // max-width: 98% !important;

          @media screen and (max-width: 705px) {
            // max-width: 80% !important;
          }
        }
      }

      img {
        max-width: 80%;

        @media screen and (max-width: 705px) {
           max-width: 85% !important;
        }
      }
    }

    &:nth-of-type(3) {
      border-top: none;
    }
    &:last-of-type {
      border-top: none;
    }

    &:hover {
      background-color: var(--background-item-color-hover);

      .wins__item--img-grey {
        opacity: 0;
      }

      .wins__item--img-colour {
        opacity: 1;
      }
    }
  }
}

@media screen and (min-width: 2000px) {
  .wins {
    &__media {
      max-width: 33%;
    }
  }
}

@media screen and (max-width: 1600px) {
  .wins {
    &__subtitle {
      padding-left: 180px;
    }

    &__description {
      padding-left: 180px;
    }

    &__media {
      padding-right: 200px;
    }

    &__list {
      padding-left: 165px;
      grid-template-columns: repeat(2, 319px);
    }
  }
}

@media screen and (max-width: 1440px) {
  .wins {
    &__subtitle {
      padding-left: 165px;
    }

    &__description {
      padding-left: 165px;
    }

    &__media {
      padding-right: 196px;
    }

    &__list {
      padding-left: 150px;
      grid-template-columns: repeat(2, 286px);
    }
  }
}

@media screen and (max-width: 1370px) {
  .wins {
    &__media {
      padding-right: 122px;
    }

    &__list {
      padding-left: 150px;
      grid-template-columns: repeat(2, 267px);
    }
  }
}
@media screen and (max-width: 960px) {
  .wins {
    &__subtitle {
      padding-left: 44px;
    }

    &__description {
      padding-left: 44px;
    }

    &__media {
      padding-right: 74px;
    }

    &__list {
      padding-left: 32px;
      grid-template-columns: repeat(2, 176.8px);
    }

    &__item {
      height: 111px;
    }

    &__media {
      &.desc {
        min-width: 260px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .wins {
    padding-bottom: 100px;

    &__media {
      width: 34%;

      &.desc {
        transform: translateX(-30px);
      }
    }

    &__left {
      margin-right: 0;
      margin-top: 155px;
    }

    &__description {
      max-width: 80%;
    }

    &__item {
      height: 112px;
      padding: 16px;
      background-size: 70% 70%;
      border: 1px solid #92A1AE;

      &:nth-of-type(1) {
        img {
          max-width: 136px;
        }
      }
      &:nth-of-type(2) {
        border-left: none;
        img {
          max-width: 140px;
        }
      }

      &:last-of-type {
        border-top: none;

        img {
          max-width: 90px;
        }
      }
    }
  }
}

@media screen and (max-width: 705px) {
  .wins {
    padding-bottom: 56px;

    &__title {
      padding-left: 44px;
    }

    &__media {
      width: 60%;
      padding-right: 0;
      margin: 0 auto 40px auto;

      &.desc {
        display: none;
      }

      &.mob {
        display: block;
        min-width: 186px;
        max-width: 60%;
      }
    }

    &__title {
      text-align: left;

      br {
        display: none;
      }

      .text-wrapper {
        &:first-of-type {
          padding: 0;
        }

        &.second-part {
          margin-left: 0%;
        }
      }
    }

    &__description {
      max-width: 100%;
    }

    &__item {
      height: 101px;

      &:nth-of-type(1) {
        img {
          max-width: 140px;
        }
      }

      &:nth-of-type(2) {
        img {
          max-width: 140px;
        }
      }

      &:nth-of-type(3) {
        img {
          max-width: 70px;
        }
      }
    }

    &__subtitle {
      width: 100%;
    }

    &__content {
      margin-top: 14px;
    }

    &__left {
      margin-top: 0;
      margin-right: 0;
    }

    &__list {
      margin-top: 20px;
    }

    &__subtitle {
      margin-bottom: 16px;
    }
  }
}

@media screen and (max-width: 660px) {
  .wins {
    &__title {
      padding-left: 20px;
    }

    &__subtitle {
      padding-left: 20px;
      padding-right: 20px;
    }

    &__description {
      padding-left: 20px;
      padding-right: 20px;
    }

    &__list {
      padding-left: 20px;
      padding-right: 20px;
      grid-template-columns: repeat(2, 1fr);
    }

    &__item {
      height: 100px;
      padding: 0;

      &:nth-of-type(1) {
        background-position: 8px 4px;

        &:hover {
          background-position: -201px 4px;
        }
      }

      &:nth-of-type(2) {
        background-position: 4px 4px;

        &:hover {
          background-position: -202px 4px;
        }
      }

      &:nth-of-type(3) {
        background-position: 26px 4px;

        &:hover {
          background-position: -227px 4px;
        }
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .wins {
    &__item {
      &:nth-of-type(3) {
        img {
          max-width: 54%;
        }
      }
    }
  }
}
</style>
